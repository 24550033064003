import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

import moment from 'moment';
import axios from 'axios';

import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'

import { CascadeSelect } from 'primereact/cascadeselect';

import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';
import 'react-image-crop/dist/ReactCrop.css'

import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"


import { useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from '../../../components/constants/api_url';
import APIServices from '../../../service/APIService';
const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const MasterList = () => {

    const [data, setData] = useState([])
    const [apidata, setAPiData] = useState([])

    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
    const [adddialog, setAddDialog] = useState(false)
    const [cropdialog, setCropDialog] = useState(false)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [config, setConfig] = useState({ selectedLocationIds: [], selectedLocation: [], selectedModule: [], selectedModuleIds: [], admin: { approver: false, user: true } })
    const [selectedconfig, setSelectedConfig] = useState({ name: 'Assigned Locations' })
    const [configdialog, setConfigDialog] = useState(false)
    const imgRef = useRef(null)
    const selector = useSelector((state) => state.user.userdetail)
    const previewCanvasRef = useRef(null)
    const [employee, setEmployee] = useState({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: false, viewer: false,approver:false  ,reviewer:false } })
    const [submitted, setSubmitted] = useState(false)
    const [filter, setFilter] = useState('')
    const forceUpdate = useForceUpdate()
    const dt = useRef(null);
    const [loctree, setLocTree] = useState([])


    useEffect(() => {

        renderData()

    }, [])

    const renderData = () => {
        let datas = []
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile)
                .then((res) => {
                    setAPiData(res.data)

                    res.data.forEach((item, index) => {
                        if (item.role === 'clientuser' && item.information.cid === selector.id) {

                            datas.push({ id: item.id, empname: item.information.empname, empdesgination: item.information.empdesgination, empid: item.information.empid, empmailid: item.email, config: item.information.config, information: item, emplocation: item.information.emplocation === undefined ? '' : item.information.emplocation })

                        }
                        if (index === res.data.length - 1) {
                            resolve(datas)
                        }
                    })
                })
        })
        promise.then((d) => { console.log(d); setData(d);  forceUpdate() })


    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
     
            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '40%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );


    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const addNewEmployee = () => {

        setSubmitted(true)

        if (checkValidMailID(employee.empmailid.trim()) && (employee.role.approver === true || employee.role.reporter === true || employee.role.viewer === true || employee.role.reviewer === true)  && employee.empname.trim().length !== 0) {
            let obj = employee
            let newObj = { information: {} }
            Object.keys(obj).forEach((i) => {
                if (i === 'empmailid') {

                } else {

                    if (i === 'emplocation') {
                        console.log(obj[i])
                        newObj.information['emplocation'] = obj[i].trim()
                    }
                    if (i !== 'id' && i !== 'information' && i !== 'emplocation') {

                        newObj.information[i] = obj[i]
                    }

                }
            })


            newObj.information['companyname'] = selector.information.companyname
            newObj.information['companyid'] = selector.userId
            newObj.information['cid'] = selector.id
            newObj['clientId'] = selector.id
            newObj.information['blocked'] = employee.information.blocked
            newObj['company'] = selector.information.companyname
            newObj['role'] = employee.information.role
            console.log(newObj)
            APIServices.patch(API.UserProfile_Edit(employee.id), newObj)
                .then((res) => {
                    setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false ,reviewer:false } })

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `Updated successfully`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                    renderData()
                }).catch((e) => {
                    setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false ,reviewer:false  } })

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                })
            setAddDialog(false)
            setSelectedConfig({ name: 'Assigned Locations' })
            setSubmitted(false)

        }

    }
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewEmployee() }} />

        </div>)
    }
 

    const editUser = (user) => {
        console.log(user.information.information.role)
        setAddDialog(true)
        setEmployee({ id: user.id, information: user.information, empname: user.empname, emplocation: user.information.information.emplocation, empdesgination: user.empdesgination, empmailid: user.information.email, emplocation: user.emplocation, role: user.information.information.role })

    }
  

    const nameTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empname}
            </>
        );
    }
    const emailTemplate = (rowData) => {
console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empmailid}
            </>
        );
    }
    const logoTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.logo} width='50' />
            </>
        );
    }
    const configTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <i onClick={() => {  }} className='material-icons'>settings</i>
            </>
        );
    }
  
   

    

    const onRoleChange = (e, obj) => {
        let loc = employee
        loc.role[obj] = !e.value
        setEmployee(loc)
        console.log(loc)
        forceUpdate()
    }
    const updateEmployee = (obj, val) => {
        let loc = employee
        loc[obj] = val
        setEmployee(loc)
        forceUpdate()
    }
    const editTemplate = (rowData) => {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <i  onClick={() => { editUser(rowData) }} className='material-icons'>edit</i>


            </div>
        );
    }

    return (
        <div className="grid p-fluid">
            {selector.role === 'clientadmin' ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{
                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`User Master List (${data.length})`} </h5>
                    </div>

                    <div>
                        <DataTable ref={dt} value={data}
                            scrollable
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={header} responsiveLayout="scroll">
                            <Column  body={editTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>
                           
                            <Column field="empname" header="Name" body={nameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="Email" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
           
                        </DataTable>
                        <Dialog visible={adddialog} style={{ width: '450px' }} header="User Role Configuration" modal className="p-fluid" footer={addDialogFooter} onHide={() => { setSubmitted(false); setAddDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">User Name <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} disabled value={employee.empname} onChange={(e) => { updateEmployee('empname', e.target.value) }} placeholder="Name" />
                               
                            </div>
                            {/* <div className="field">
                                    <label htmlFor="refdate">User ID</label>
                                    <InputText type={'text'} disabled={employee.id !== undefined} value={employee.empid} onChange={(e) => { updateEmployee('empid', e.target.value) }} placeholder="Unique ID for login" />
                                    {submitted && employee.empid.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>User Id is mandatory</small>}

                                </div> */}
                            <div className="field">
                                <label htmlFor="refdate">User E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled={employee.id !== undefined} type={'email'} value={employee.empmailid} onChange={(e) => { updateEmployee('empmailid', e.target.value) }} placeholder="Mail ID" />
                               

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Location ( Physical location of user if neccessary )</label>
                                <InputText type={'text'} disabled value={employee.emplocation} onChange={(e) => { updateEmployee('emplocation', e.target.value) }} placeholder="location name" />


                            </div>

                            <div className="field">
                                <label htmlFor="refdate">Supervisor <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled type={'email'} value={employee.empdesgination} onChange={(e) => { updateEmployee('empdesgination', e.target.value) }} placeholder="Supervisor mail id" />
                              
                            </div>
                            <div >
                                <label htmlFor="refdate">Choose Role <span style={{ color: 'red' }}>*</span></label>
                                <div style={{ display: 'flex', marginTop: 5, marginLeft: 10 }}>
                                    {/* <div className="flex align-items-center">
                                        <Checkbox inputId="rep" disabled name="pizza" value={employee.role.reporter} onChange={(e)=>{onRoleChange(e,'reporter')}} checked={employee.role.reporter === true} />
                                        <label htmlFor="rep" className="ml-2">Reporter</label>
                                    </div> */}
                                    <div style={{ marginLeft: 10 }} className="flex align-items-center">
                                        <Checkbox inputId="view" name="pizza" value={employee.role.reporter} onChange={(e) => { onRoleChange(e, 'reporter') }} checked={employee.role.reporter === true} />
                                        <label htmlFor="view" className="ml-2">Reporter</label>
                                    </div>
                                    <div style={{ marginLeft: 10 }} className="flex align-items-center">
                                        <Checkbox inputId="view" name="pizza" value={employee.role.approver} onChange={(e) => { onRoleChange(e, 'approver') }} checked={employee.role.approver === true} />
                                        <label htmlFor="view" className="ml-2">Approver</label>
                                    </div>
                                    <div style={{ marginLeft: 10 }} className="flex align-items-center">
                                        <Checkbox inputId="view" name="pizza" value={employee.role.reviewer} onChange={(e) => { onRoleChange(e, 'reviewer') }} checked={employee.role.reviewer === true} />
                                        <label htmlFor="view" className="ml-2">Reviewer</label>
                                    </div>
                                    <div style={{ marginLeft: 10 }} className="flex align-items-center">
                                        <Checkbox inputId="view" name="pizza" value={employee.role.viewer} onChange={(e) => { onRoleChange(e, 'viewer') }} checked={employee.role.viewer === true} />
                                        <label htmlFor="view" className="ml-2">Viewer</label>
                                    </div>
                                </div>
                                {submitted && employee.role.reporter === false && employee.role.viewer === false && employee.role.reviewer === false  && <small className="p-invalid" style={{ color: 'red' }}>Select role</small>}

                            </div>



                        </Dialog>


                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )
}

export default MasterList

