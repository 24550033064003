import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppConfig } from './AppConfig';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from "react-redux";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import Login from './pages/Login';
import ClimateChangeInitivative from './pages/ccInitivative';
import clientUserDCFAssignment from './pages/clientUserDCFAssignment';
import locationConfigByClient from './pages/locationConfigByClient';
import AddClientSupplier from './components/addClientSupplier';
import ClientIndicators from './pages/ClientIndicators';
import clientDCFSupplierAssignment from './pages/clientDCFSupplierAssignment';
import DashboardCC from './Dashboard/DashboardCC';
import approvedLogs from './Dashboard/approvedLogs';
import QualitativeDCFListing from './pages/QualitativeDCFListing';
// import RFSubmittedPreview from './pages/RFSubmittedPreview';
import { API } from './components/constants/api_url';
import PasswordReset from './pages/passwordReset';
import Assurance from './pages/assurance_';
import '../src/Styles/custom.css'
import '../src/Styles/ui_ux.css'
import APIServices from './service/APIService';
import AdminHomeScreen from './pages/AdminHomeScreen';
import ReportListing from './pages/ReportListing';
import Sidebar from './components/sidebar/sidebar';
import '../src/components/sidebar/sidebar.scss'
import RFInputEntrySubmission from './pages/client/form/RFInputEntrySubmission';
import UserListing from './STT/pages/admin/userListing';

import CarbonFootPrinting from './pages/reports/CarbonFootPrinting';
import Nasdaq_Report from './pages/reports/Nasdaq_Report';
import CDP_Report from './pages/reports/CDP_Report';
import SGX_Report from './pages/reports/SGX_Report';
import EcoVadis_report_ from './pages/reports/EcoVadis_report_';
import GRI_Report from './pages/reports/GRI_Report';
import dummy_report from './pages/reports/dummy_report';
import BRSR_Report__ from './pages/reports/BRSR_Report__';
import MasterList from './STT/pages/admin/esgUserListing';
import QualitativeRFListing from './pages/QualitativeRFListing';
import RFInputEntrySubmissionPreview from './pages/client/form/RFInputEntrySubmissionPreview';
import AssignDcfToUser from './STT/pages/admin/AssignDcfToUser';
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import OverallQuantitativeSubmissions from './Dashboard/OverallQuantitativeSubmissions';
import quantitativeOverallSubmissionPreview from './pages/client/form/quantitativeOverallSubmissionPreview';
import OverallQuantitativeSubmissionStatus from './Dashboard/OverallQuantitativeSubmissionStatus';

const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory()
    const config = useSelector(state => state.user.userdetail)
    const dispatch = useDispatch()
    PrimeReact.ripple = true;


    const [stdlist, setSTDList] = useState([])
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {

        if (localStorage.getItem('token') === null && !location.pathname.match('/reset-password-finish')) {
            history.push('/')
            sessionStorage.removeItem('temp')
        }
    }, [])

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
                setSTDList(res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 }))
            })
        }

        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        dispatch(resetOverallPendingData())
             dispatch(resetLoggedUserDetail());
             localStorage.clear()
             sessionStorage.clear()
             history.push('/')       
     }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }



    const superadmin = [

        { label: 'Home', icon: 'pi pi-fw pi-home', to: '/client_home' },

        { label: 'Performance Dashboard', icon: 'pi pi-fw pi-desktop', to: '/client_performance' },
        { label: 'Reports & Disclosure', to: '/report_list', icon: 'pi pi-book' },
        { label: 'Material Topics', to: '/client_indicators', icon: 'material-icons', gicon: 'traffic' },
        // { label: 'Estimation Tools', to: '', icon: 'material-icons', gicon: 'calculate' },
        { label: 'Qualitative Responses', to: '/qualitative_rf_inputs', icon: 'material-icons', gicon: 'calculate' },
        { label: 'Data Assignment', to: '/quantitative_assignment_user', icon: 'material-icons', gicon: 'rebase_edit' },
        { label: 'DCF Submission Status', to: '/overall_quantitative_status', icon: 'material-icons', gicon: 'calculate' },
        // { label: 'Assurance', to: '/assurance', icon: 'material-icons', gicon: 'summarize' },
        ,
        {
            label: 'Settings', icon: 'pi pi-cog', to: '/client/',
            items: [
                { label: 'User Management', to: '/client/add_user', icon: 'pi pi-user-plus' },
                { label: 'Master List', to: '/client/user_list', icon: 'pi pi-user-plus' },
                { label: 'Goals,Targets & Actions', to: '/client/goals', icon: 'material-icons', gicon: 'trending_up' },
                { label: 'View Site Configuration', to: '/client/location_config', icon: 'material-icons', gicon: 'edit_location_alt' },
                // { label: 'User Config', to: '/client/user_config', icon: 'pi pi-sliders-h' },
                // { label: 'Approver Config', to: '/client/approver_config', icon: 'pi pi-sliders-h' },
            ]
        },
        {  label: 'Logout', icon: 'pi pi-sign-out ',  to: '/logout', exit:true }

        // command: () => { window.open(window.location.origin + '/#/client_user/homescreen', '_blank').sessionStorage.setItem('temp', true) } }
        // Object.keys(config).length !== 0 && config.role === 'clientadmin' && config.information.report.length !== 0 &&
        // {
        //     label: 'Reports & Disclosure', icon: 'pi pi-fw pi-search',
        //     items: getRenderSTDs(config.information.report)
        // },



    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {localStorage.getItem('token') &&
                <>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                    {(config.information.role === undefined || !config.information.role.reporter) && (!sessionStorage.getItem('temp')) && <div className="layout-menu-container">
                        <Sidebar logout={onMenuItemClick}  onToggleMenuClick={onToggleMenuClick} menuitems={superadmin} />
                    </div>
                    }

                    {/* {(config.information.role === undefined || !config.information.role.reporter) && (!sessionStorage.getItem('temp')) && <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={Object.keys(config).length !== 0 && (config.role === 'eisqradmin' ? eisqradmin : config.role === 'clientadmin' ? superadmin : config.information.role.reporter ? edituser : approver)} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />


                    </div>
                    } */}

                </>
            }
            <div className={(localStorage.getItem('token') && (config.information.role === undefined || !config.information.role.reporter)) && (!sessionStorage.getItem('temp')) && "layout-main-container bg-smoke"}>
                <div className="layout-main" style={{ marginTop: 10 }}>


                    <Route exact path="/" component={Login} />
                    <Route path='/reset-password-finish' component={PasswordReset} />

                    {localStorage.getItem('token') &&
                        <>
                            {/* SideBar & Topbar   */}
                            <Route path='/quantitative_assignment_user' component={AssignDcfToUser} />
                            <Route path='/client_home' component={AdminHomeScreen} />
                            <Route path='/client_performance' component={DashboardCC} />
                            <Route path='/report_list' component={ReportListing} />
                            <Route path='/client_indicators' component={ClientIndicators} />
                            <Route path='/qualitative_rf_inputs' component={QualitativeRFListing} />
                            <Route path='/dcf_user_assign' component={clientUserDCFAssignment} />
                            <Route path='/dcf_supplier_assign' component={clientDCFSupplierAssignment} />
                            <Route path='/approved_logs' component={approvedLogs} />
                            <Route path='/assurance' component={Assurance} />
                            <Route path='/client/add_user' component={UserListing} />
                            <Route path='/client/user_list' component={MasterList} />

                            <Route path='/client/add_supplier' component={AddClientSupplier} />
                            <Route path='/client/goals' component={ClimateChangeInitivative} />
                            <Route path='/client/location_config' component={locationConfigByClient} />
                            {/* RF */}
                            <Route path='/rf_input_entry/:id' component={RFInputEntrySubmission} />
                            <Route path='/rf_submission_preview/:id' component={RFInputEntrySubmissionPreview} />
                            {/* Report */}
                            <Route path='/brsr_report' component={BRSR_Report__} />
                            <Route path='/cfp' component={CarbonFootPrinting} />
                            <Route path='/nasdaq_report' component={Nasdaq_Report} />
                            <Route path='/cdp_report' component={CDP_Report} />
                            <Route path='/sgx_report' component={SGX_Report} />
                            <Route path='/ecovadis_report' component={EcoVadis_report_} />
                            <Route path='/gri_report' component={GRI_Report} />
                            <Route path='/dummy_report' component={dummy_report} />
                            <Route path='/overall_quantitative_submission_status' component={OverallQuantitativeSubmissionStatus} />
                            <Route path='/overall_quantitative_status' component={OverallQuantitativeSubmissions} />
                            <Route path='/data_input_status/:id/:id2' component={quantitativeOverallSubmissionPreview} />
                       
                        </>
                    }

                </div>
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;

